<template>
  <div class="mainwrap coverad" v-loading="loading">
        <div class="title">
            蓋板廣告
            <div class="addbtn" v-if="permissionType === 2">
                <el-button type="success" @click="addItem">新增</el-button>
            </div>
        </div>
        <div class="searchform">
            <el-form
                label-width="100px"
                :inline="true"
                :model="searchForm"
                class="demo-form-inline"
            >
                <el-form-item label="標題：">
                <el-input v-model="searchForm.Name" placeholder="標題"></el-input>
                </el-form-item>
                <el-form-item label="開始時間：">
                <el-date-picker
                    :editable="false"
                    value-format="yyyy-MM-dd"
                    v-model="searchForm.StartTime"
                    type="date"
                    placeholder="請選擇時間"
                >
                </el-date-picker>
                </el-form-item>
                <el-form-item label="結束時間：">
                <el-date-picker
                    :editable="false"
                    value-format="yyyy-MM-dd"
                    v-model="searchForm.EndTime"
                    type="date"
                    placeholder="請選擇時間"
                >
                </el-date-picker>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="onSearch">查询</el-button>
                <el-button @click="reSet">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="tablewrap">
            <el-table :data="tableData" style="width: 100%" stripe>
                <el-table-column prop="name" label="標題"> </el-table-column>
                <el-table-column prop="linkType" label="鏈接類型"> </el-table-column>
                <el-table-column prop="startTime" label="推送時間"> </el-table-column>
                <el-table-column prop="endTime" label="結束時間"> </el-table-column>
                <el-table-column prop="isActive" label="是否發佈"> </el-table-column>
                <el-table-column label="操作" width="220" v-if="permissionType === 2">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" plain>
                            编辑
                        </el-button>
                        <div class="delbtn">
                            <el-popconfirm
                            confirm-button-text='確定'
                            cancel-button-text='取消'
                            confirm-button-type="danger"
                            icon="el-icon-info"
                            icon-color="red"
                            title="確定刪除？"
                            @confirm="delItem(scope.row.id)"
                            >
                                <el-button type="danger" slot="reference">刪除</el-button>
                            </el-popconfirm>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination">
            <el-pagination
                background
                :hide-on-single-page="isSinglePage"
                layout="prev, pager, next,jumper"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="currentPage"
                :total="tableDataTotal"
            >
            </el-pagination>
        </div>

  </div>
</template>

<script>
import * as api from "./api"
import { getStore} from '@/utils/storage';
export default {
  name: 'coverAd',
    data(){
        return{
          loading:false,
          searchForm:{
              Name:"",
              StartTime:"",
              EndTime: ""
          },
          tableData:[],
          isSinglePage:false,
          pageSize:10,
          currentPage:1,
          tableDataTotal:0,
          permissionType: -1
        }
    },
    created(){
      this.getAdlist();
      this.permissionType = parseInt(getStore("permissionType"));
    },
    activated(){
      this.getAdlist();
    },
    methods:{
      getAdlist(){
          let params={
              Name:this.searchForm.Name,
              StartTime:this.searchForm.StartTime? this.searchForm.StartTime+" "+"00:00:00" : "",
              EndTime:this.searchForm.EndTime? this.searchForm.EndTime+" "+"00:00:00" : "",
              SkipCount:(this.currentPage-1)*this.pageSize,
              MaxResultCount:this.pageSize
          }
          this.loading=true;
          api.getCoverBanner(params).then(res=>{
              if(res.systemCode===200){
                  this.tableData=res.data.items;
                  this.tableDataTotal=res.data.totalCount;
              }
              this.loading=false;
          })
      },
      addItem(){
          this.$router.push({
              path: "/cms/coveradedit",
              query: {
                  type: "create",
              },
          });
      },
      onSearch(){
          this.currentPage=1;
          this.getAdlist();
      },
      reSet(){
          this.currentPage=1;
          this.searchForm={
              Name:"",
              StartTime:"",
              EndTime: ""
          };
          this.getAdlist();
      },
      handleEdit(item){
          this.$router.push({
              path: "/cms/coveradedit",
              query: {
                  type: "edit",
                  id: item.id,
              },
          });
      },
      delItem(id){
          this.loading=true;
          api.deleteCover(id).then(res=>{
              if(res.systemCode===200){
                  this.$message.success('刪除成功');
                  if (this.tableData.length <= 1) {
                      this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
                  }
                  this.getAdlist();
              }
                this.loading=false;
          })
      },
      handleCurrentChange(val){
          this.currentPage=val;
          this.getAdlist();
      }
    }
}
</script>

<style lang="scss" scoped>
.coverad {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
